<template>
  <div class="app-container">
    <el-card shadow="hover" class="left">
      <el-form ref="form" :model="menu_data" label-width="80px">
          <el-form-item label="分类名">
              <el-input v-model="menu_data.name" style="max-width:300px" maxlength="10" minlength="2"></el-input>
          </el-form-item>
          <el-form-item label="开启状态">
              <el-switch v-model="menu_data.status" :active-value="1" :inactive-value="0"></el-switch>
          </el-form-item>
          <el-form-item label="类型">
              <el-select v-model="menu_data.type" placeholder="选择分类类型">
                <el-option
                  v-for="item in type_arr"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item>
              <el-button type="primary" @click="update">修改</el-button>
          </el-form-item>
      </el-form>
    </el-card>
    <el-card class="right">
      <div style="margin:10px 0">当前音效分类：{{menu_data.name}} 音效个数 {{total}}</div>
      <el-table
        v-loading="listLoading"
        :data="list"
        element-loading-text="Loading"
        fit
        highlight-current-row
        :row-class-name="bgmRowClassCallback"
      >
        <el-table-column align="center" label="ID" width="95">
          <template slot-scope="scope">
            {{ scope.$index+1 }}
          </template>
        </el-table-column>
        <el-table-column label="音效名">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column label="底色">
          <template slot-scope="scope">
            <template v-if="!scope.row.rgb">未设置</template>
            <template v-else>
              <div style="width:30px;height:20px;border-radius:5px" :style="{backgroundColor:scope.row.rgb}"></div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="95">
          <template slot-scope="scope">
            <template v-if="scope.row.status==1">显示</template>
            <template v-else>不显示</template>
          </template>
        </el-table-column>
        <el-table-column label="编辑" align="center">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="editSound(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeMenuSound(scope.row)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
    </el-card>

    <el-dialog
      :append-to-body="true"
      title="编辑音效"
      :visible.sync="dialogVisible2"
      width="30%">

        <el-form ref="form" :model="editSoundForm" label-width="80px">
            <el-form-item label="音效名">
                <el-input v-model="editSoundForm.name"></el-input>
            </el-form-item>
            <el-form-item label="开启状态">
                <el-switch v-model="editSoundForm.status" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item label="按钮颜色">
                <el-color-picker :predefine="predefineColors" color-format="rgb" v-model="editSoundForm.rgb"></el-color-picker>
            </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="editSoundDone" size="mini">确认设置</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
/* eslint-disable */ 
import { ReadOneMenu, updateOneMenuSoundsSort, removeMenuSound,update } from '@/api/categoryv2'
import { updateSound } from '@/api/soundsv2'
export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },
  data() {
    return {
      list: null,
      listLoading: true,
      page_num:1,
      page_size:5,
      total:0,
      search_op:{},
      menu_data:{},
      currDragOverSoundIndex:null, //当前拖拽经过的音效按钮
      currDragOverSoundDirection:null, //当前拖拽经过的音效放置方向  top bottom
      currDragSoundIndex:null, //当前拖拽的音效按钮
      type_arr:[
        {name:'背景音乐',value:'music'},
        {name:'音效包',value:'sound'},
        {name:'通用',value:'all'},
      ],
      dialogVisible2:false,
      editSoundForm:{},
      predefineColors: [
        'rgb(254, 67, 101)',
        'rgb(252, 157, 154)',
        'rgb(249, 205, 173)',
        'rgb(200, 200, 169)',
        'rgb(131, 175, 155)',
        '#ff783c',
        '#5a5a5a',
        '#a8a09c',
        '#5983ff',
        '#ff599e',
        '#ff8d42',
        '#5392ff',

        '#27c7c1',
        '#ffd340',
        '#bd98f7',
        '#b2effc',
        '#ffd5d9',

        '#1ddbb2',
        '#242e2d',
        '#3c3b3d',
      ],
    }
  },
  created() {
    this.fetchData()
  },
  mounted(){
  },
  methods: {
    editSound(row){
      console.log("设置")
      this.dialogVisible2 = true
      this.editSoundForm = row
    },
    editSoundDone(){
      
      let op = {
        'sound_id':this.editSoundForm.id,
        ...this.editSoundForm
      }
      updateSound(op).then(res=>{
        if(res.code == 1){
          this.$message({
            type:"success",
            message:'修改成功'
          })
          this.dialogVisible2 = false
          this.fetchData()
        }
      }).catch(err=>{
        this.$message({
          type:"error",
          message:'设置失败'
        })
      })
    },
    update(){
      let op = {
        name:this.menu_data.name,
        category_v2_id:this.menu_data.id,
        status:this.menu_data.status,
        type:this.menu_data.type,
      }
      update(op).then(response => {
        if(response.data.code = 1){
          this.$message({
            type:"success",
            message:'修改成功'
          })
        }
      })
    },
    removeMenuSound(row){
      // if(row.sounds_num>0){
      //   this.$message({
      //     type:"warning",
      //     message:'无法删除，该分类下包含音效'
      //   })
      //   return;
      // }
      this.$confirm('确认删除该音效, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeMenuSound({category_v2_sounds_id:row.category_v2_sounds_id}).then(response => {
          if(response.data.code = 1){
            this.$message({
              type:"success",
              message:'删除成功'
            })
            this.fetchData(false)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    play(row){
      this.audio.url = row.full_path;
      this.audio.name = row.name;
      this.$refs.aplayer.play();
    },
    handleSizeChange(val) {
      this.page_size = val
      this.fetchData(false)
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.page_num = val
      this.fetchData(false)
      console.log(`当前页: ${val}`);
    },
    fetchData(isSearch=false) {
      if(isSearch){
        this.page_num = 1;
      }
      const op = {
        // page_num:this.page_num,
        // page_size:this.page_size,
        category_v2_id:this.$route.query.menu_id
      }
      Object.assign(op,this.search_op)
      this.listLoading = true
      ReadOneMenu(op).then(response => {
        this.list = response.data.data.sounds_data.list
        this.total = response.data.data.sounds_data.count
        this.listLoading = false
        this.menu_data = response.data.data.category_v2
        this.initDragDropEvent()
      })
    },
    bgmRowClassCallback(arg){
      const {row,rowIndex} = arg
      // console.log(row,index)
      let classStr = 'bgmRow ';
      if(rowIndex == this.currDragSoundIndex) classStr+= 'drag-curr ';
      // if(rowIndex == this.currDragOverSoundIndex) classStr+= 'drag-over ';
      if(this.currDragSoundIndex !=null && this.currDragOverSoundIndex==rowIndex && this.currDragOverSoundDirection=='top') classStr+= 'drag-over-top ';
      if(this.currDragSoundIndex !=null && this.currDragOverSoundIndex==rowIndex && this.currDragOverSoundDirection=='bottom') classStr+= 'drag-over-bottom ';
      return classStr;
    },
    initDragDropEvent(){
      this.$nextTick(()=>{
        //拖拽排序
        let ref_bgms = window.document.getElementsByClassName('bgmRow')
        if(ref_bgms){
          ref_bgms.forEach((d,index)=>{
            console.log("设置了可以拖动")
            let isAddListener = d.getAttribute('data-i')
            if(isAddListener) return;

            d.setAttribute('draggable',true)
            d.setAttribute('data-i',index)
            //开始拖动音效按钮
            d.addEventListener('dragstart',(e)=>{
              const drag_i = e.target.getAttribute('data-i');
              this.currDragSoundIndex = drag_i
              e.dataTransfer.setData("data_i_drag",drag_i);
            })

            d.addEventListener('dragend',(e)=>{
              console.log("拖动完成")
              this.currDragOverSoundIndex = null;
              this.currDragSoundIndex = null;
              this.currDragOverSoundDirection = null
            })
            d.addEventListener("drop",(e)=>{
              e.preventDefault();

              const data_i_drag = parseInt( e.dataTransfer.getData('data_i_drag') )
              if(data_i_drag){
                // console.log('获取到的data_i_drag',data_i_drag)
              }
              const data_i_drop = parseInt( d.getAttribute('data-i') )
              // console.log("当前音效块data_i_drop",data_i_drop)

              //交换位置
              this.soundLayout(data_i_drag,data_i_drop);

              this.currDragOverSoundIndex = null;
              this.currDragSoundIndex = null;
              this.currDragOverSoundDirection = null
            });
            d.addEventListener("dragover", (e) => {
              e.stopPropagation();
              e.preventDefault();
              e.dataTransfer.setData("data_i_drop",d.getAttribute('data-i'));
              this.currDragOverSoundIndex = d.getAttribute('data-i');
              // console.log(d.getAttribute('data-i'))
              // e.dataTransfer.dropEffect = "move"
              // console.log("当前音效块drop offsetLeft",d.offsetLeft)
              // console.log("当前音效块drop offsetTop",d.offsetTop)
              // console.log("当前音效块drop clientWidth",d.clientWidth)
              // console.log("当前音效块drop clientHeight",d.clientHeight)

              // console.log("当前音效块drop clientX",e.clientX)
              // console.log("当前音效块drop offsetY",e.offsetY)
              // console.log("当前音效块drop pageX",e.pageX)
              // console.log("当前音效块drop pageY",e.pageY)

              let c = e.offsetY
              if(c>d.clientHeight/2){
                // console.log("下边")
                this.currDragOverSoundDirection = 'bottom'
              }else{
                this.currDragOverSoundDirection = 'top'
                // console.log("上边")
              }

            })
            d.addEventListener("dragleave", (e) => {
              e.preventDefault();
              this.currDragOverSoundIndex = null;
              // e.dataTransfer.dropEffect = "move"
            })
          })
        }

      })
    },
			//音效移动位置
			soundLayout(drag_i=null,drop_i=null){
				if(drag_i ==null || drop_i==null) return;
				if(drag_i == drop_i) return;
				let musics = this.list;
				let drag = musics.splice(drag_i,1); //被拖动的 return array

				let newIndex = null
				if(this.currDragOverSoundDirection == 'top'){
					//如果在他前面抽出了一个元素，实际drop_i会变小1位
					if(drag_i<drop_i){
						musics.splice(drop_i-1,0,...drag)
						newIndex = drop_i-1
					}else{
						//后面抽出不变
						newIndex = drop_i
						musics.splice(drop_i,0,...drag)
					}
				}else{
					if(drag_i<drop_i){
						newIndex = drop_i
						musics.splice(drop_i,0,...drag)
					}else{
						newIndex = drop_i+1
						musics.splice(drop_i+1,0,...drag)
					}
				}
        console.log("新列表",this.list)
        let sounds_id_arr = this.list.map(v=>{return parseInt(v.sound_id)})
        let op = {
          menu_id:this.$route.query.menu_id, 
          'sounds_id_arr':sounds_id_arr.join(',')
        }
        updateOneMenuSoundsSort(op).then(res=>{
            this.$message({
                message: '排序成功!',
                type: 'success'
            })
        }).catch(err=>{
          this.$message({
              message: '排序失败!',
              type: 'error'
          })
        })
				// this.musicList = musics
			},
  }
}
</script>
<style lang="scss" scoped>
  .app-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .right{
      flex: 1;
      margin-left:10px;
    }
  }
  .drag-over{
    td{
      // border-top: 1px solid red !important;
      // border-bottom: 1px solid red !important;
    }
  }
  .drag-over-top{
    td{
      border-top: 2px solid red !important;
    }
  }
  .drag-over-bottom{
    td{
      border-bottom: 2px solid red !important;
    }
  }
  .drag-curr{
    // cursor:move !important;
    opacity: .3;
  }
</style>